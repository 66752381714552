import styles from './Order.module.scss';
import classNames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Layout/components/Header';
import SETTINGS from '../../setting.json';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';
import donhangmoi from '../../assets/images/new_mission.png';
import thanhcong from '../../assets/images/success-icon-style.png';
import clock from '../../assets/images/clock.png';
import shopee from '../../assets/images/Logo.png';
import axios from 'axios';
const cx = classNames.bind(styles);

function formateT(params) {
    let result = params < 10 ? '0' + params : params;
    return result;
}

function timerJoin2(params = '') {
    let date = '';
    if (params) {
        date = new Date(Number(params));
    } else {
        date = new Date();
    }
    let years = formateT(date.getFullYear());
    let months = formateT(date.getMonth() + 1);
    let days = formateT(date.getDate());

    let hours = formateT(date.getHours());
    let minutes = formateT(date.getMinutes());
    let seconds = formateT(date.getSeconds());
    return years + '-' + months + '-' + days + ' ' + hours + ':' + minutes + ':' + seconds;
}

function formatMoney(money = 0) {
    return String(money).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
}

const checkToken = () => {
    let accessToken = localStorage.getItem('auth');
    if (!accessToken) {
        localStorage.removeItem('auth');
        window.location.href = '/account/login';
    } else {
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/me`, {
                headers: {
                    'x-access-token': accessToken,
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'error') {
                    localStorage.removeItem('auth');
                    window.location.href = '/account/login';
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
};

function History({ title }) {
    document.title = title;
    let [type_mission, setType] = useState('pending');
    let [mission, setMission] = useState('');
    let navigate = useNavigate();
    const [load, setLoad] = useState(false);
    const [isDon, setIsDon] = useState('');
    useEffect(() => {
        axios
            .get(`${SETTINGS.BASE_URL}/api/webapi/mission/history`, {
                params: {
                    type: type_mission,
                },
                headers: {
                    'x-access-token': localStorage.getItem('auth'),
                    'Access-Control-Allow-Origin': '*',
                },
            })
            .then(function (response) {
                let data = response.data;
                if (data.status === 'ok') {
                    if (data.data.missions.length === 0 && type_mission === 'pending') {
                        // toast.warn('Hiện tại chưa có đơn hàng dành cho bạn!', { theme: 'light' });
                        setIsDon(data.data.trang_thai_don);
                        setType('all');
                    }
                    // console.log(data.data);
                    setMission(data.data.missions);
                }
            })
            .catch(function (error) {
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
    }, [type_mission]);

    useEffect(() => {
        checkToken();
    }, []);

    function confirmMission() {
        setLoad(true);
        const headers = {
            'x-access-token': localStorage.getItem('auth'),
            'Access-Control-Allow-Origin': '*',
        };
        axios
            .post(
                `${SETTINGS.BASE_URL}/api/webapi/mission/confirm-mission`,
                { newMission: 'JKQLGHKLH' },
                {
                    headers,
                },
            )
            .then(async function (response) {
                let data = response.data;
                setLoad(false);
                if (data.status === 'ok') {
                    if (data.data.type === 2) return toast.warn('Nhiệm vụ không tồn tại !', { theme: 'light' });
                    if (data.data.type === 3) return toast.warn('Bạn đã làm nhiệm vụ này rồi !', { theme: 'light' });
                    if (data.data.type === 4) return toast.warn('Cấp bậc của bạn không đủ !', { theme: 'light' });
                    if (data.data.type === 0)
                        return toast.warn('Số dư không đủ, vui lòng nạp thêm vốn !', { theme: 'light' });
                    if (data.data.type === 1) {
                        setType('all');
                        setTimeout(() => {
                            navigate('/order/index');
                        }, 1200);
                        return toast.success('Xác nhận đơn hàng thành công !', { theme: 'light' });
                    }
                }
            })
            .catch(function (error) {
                toast.error('Có lỗi xảy ra', { theme: 'light' });
            });
    }

    if (!Array.isArray(mission)) return false;

    return (
        <div className="history-order">
            <Header color="rgb(255, 82, 89)" title="Lịch sử đặt hàng" param="/my" />
            <div className="header-order flex justify-between border-b-2">
                <div
                    onClick={() => setType('all')}
                    className={cx('history-progress', { 'px-[10px] py-[15px] text-[16px] font-medium': true })}
                    style={{ color: `${type_mission === 'all' ? '#ff3c61' : '#C4A6A7'}` }}
                >
                    Tất cả
                </div>
                <div
                    onClick={() => setType('pending')}
                    className={cx('history-progress', { 'px-[10px] py-[15px] text-[16px] font-medium': true })}
                    style={{ color: `${type_mission === 'pending' ? '#ff3c61' : '#C4A6A7'}` }}
                >
                    Kiểm duyệt
                </div>
                <div
                    onClick={() => setType('success')}
                    className={cx('history-progress', { 'px-[10px] py-[15px] text-[16px] font-medium': true })}
                    style={{ color: `${type_mission === 'success' ? '#ff3c61' : '#C4A6A7'}` }}
                >
                    Thành công
                </div>
                <div
                    onClick={() => setType('fail')}
                    className={cx('history-progress', { 'px-[10px] py-[15px] text-[16px] font-medium': true })}
                    style={{ color: `${type_mission === 'fail' ? '#ff3c61' : '#C4A6A7'}` }}
                >
                    Thất bại
                </div>
            </div>
            <div className="content px-[12px] py-[17px]">
                <div className="list-items">
                    {isDon == 2 && (type_mission == 'all' || type_mission == 'pending') && (
                        <div
                            className="item-data relative p-[15px] mb-[15px]"
                            style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px' }}
                        >
                            <img
                                className="absolute w-[94px] h-[25px] right-[-4px] top-[-4px]"
                                // src={data.status === 0 ? donhangmoi : thanhcong}
                                src={donhangmoi}
                                alt=""
                            />
                            <div className="by flex">
                                <img className="w-[19px] h-[19px]" src={shopee} alt="" />
                                <span className="text-[#a6c4c3] text-xl ml-[5px]">Từ: Shopee</span>
                            </div>
                            <div className={cx('title', { 'text-[16px] py-[5px] font-semibold': true })}>
                                CHỜ PHÂN PHỐI ĐƠN HÀNG
                            </div>
                            <div className="box-content">
                                <div className="flex">
                                    <div className="flex flex-col">
                                        <img className="w-[90px] h-[90px] rounded-lg" src={clock} alt="" />
                                    </div>

                                    <div className="px-[15px] flex-1">
                                        <div className="info flex justify-between">
                                            <div className="">
                                                <p className="text-[#a6c4c3] text-xl">Số lượng(VND)</p>
                                                <p className="text-[#0dc253] font-bold">+{formatMoney(0)} ₫</p>
                                            </div>
                                            <div>
                                                <p className="text-[#a6c4c3] text-xl">Lợi nhuận(VND)</p>
                                                <p className="text-[#ffa900] font-bold">+{formatMoney(0)} ₫</p>
                                            </div>
                                        </div>
                                        <div className="id-order mt-[5px]">
                                            <p className="text-[#a6c4c3] text-xl my-[2px]">Mã đơn: ''</p>
                                        </div>
                                        <div className="text-[#a6c4c3] text-xl my-[2px]">Thời gian: ''</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {mission &&
                        mission.map((data, index) => {
                            return (
                                <div
                                    key={index}
                                    className="item-data relative p-[15px] mb-[15px]"
                                    style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px' }}
                                >
                                    {data.status === 2 || (
                                        <img
                                            className="absolute w-[94px] h-[25px] right-[-4px] top-[-4px]"
                                            // src={data.status === 0 ? donhangmoi : thanhcong}
                                            src={type_mission === 'pending' ? donhangmoi : thanhcong}
                                            alt=""
                                        />
                                    )}
                                    <div className="by flex">
                                        <img className="w-[19px] h-[19px]" src={shopee} alt="" />
                                        <span className="text-[#a6c4c3] text-xl ml-[5px]">Từ: Shopee</span>
                                    </div>
                                    <div className={cx('title', { 'text-[16px] py-[5px] font-semibold': true })}>
                                        {data.name_mission}
                                    </div>
                                    <div className="box-content">
                                        <div className="flex">
                                            <div className="flex flex-col">
                                                <img className="w-[90px] h-[90px] rounded-lg" src={data.image} alt="" />

                                                {type_mission === 'pending' && !load && (
                                                    <button
                                                        style={{
                                                            fontSize: '12px',
                                                            backgroundColor: '#ff5259',
                                                            marginTop: '15px',
                                                            borderRadius: '5px',
                                                            color: '#fff',
                                                            padding: '5px 5px',
                                                            width: '100%',
                                                        }}
                                                        onClick={confirmMission}
                                                    >
                                                        Gửi đơn hàng
                                                    </button>
                                                )}
                                            </div>

                                            <div className="px-[15px] flex-1">
                                                <div className="info flex justify-between">
                                                    <div className="">
                                                        <p className="text-[#a6c4c3] text-xl">Số lượng(VND)</p>
                                                        <p className="text-[#0dc253] font-bold">
                                                            +{formatMoney(data.price)} ₫
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p className="text-[#a6c4c3] text-xl">Lợi nhuận(VND)</p>
                                                        <p className="text-[#ffa900] font-bold">
                                                            +{formatMoney(data.receive)} ₫
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="id-order mt-[5px]">
                                                    <p className="text-[#a6c4c3] text-xl my-[2px]">
                                                        Mã đơn: {data.id_mission}
                                                    </p>
                                                </div>
                                                <div className="text-[#a6c4c3] text-xl my-[2px]">
                                                    Thời gian: {timerJoin2(data.time)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

export default History;
